import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { AuthorDataFragmentDoc } from './GetAuthorByUrl.query';
export type GetTitleDetailSnippetsQueryVariables = Types.Exact<{
	fullPath: Types.Scalars['String']['input'];
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	fetchAuthor?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type GetTitleDetailSnippetsQuery = {
	__typename?: 'Query';
	urlV2: {
		__typename?: 'Url';
		id: string;
		node:
			| { __typename?: 'Article'; id: string }
			| { __typename?: 'Author'; id: string }
			| { __typename?: 'Bundle'; id: string }
			| { __typename?: 'Device'; id: string }
			| { __typename?: 'Episode'; id: string }
			| { __typename?: 'GenericTitleList'; id: string }
			| { __typename?: 'Genre'; id: string }
			| {
					__typename?: 'Movie';
					id: string;
					content: {
						__typename?: 'MovieContent';
						articles: Array<{
							__typename?: 'Article';
							id: string;
							author?: {
								__typename?: 'Author';
								id: string;
								content: {
									__typename?: 'AuthorContent';
									bio: string;
									clearName: string;
									imageUrl?: string | null;
									fullPath: string;
									alumniOf: Array<string>;
									brand: string;
									languagesSpoken: Array<any>;
									occupation: Array<string>;
									publications?: Array<{
										__typename?: 'AuthorSpecificLink';
										name: string;
										url: string;
									}> | null;
									socialHandles?: Array<{
										__typename?: 'AuthorSpecificLink';
										name: string;
										url: string;
									}> | null;
								};
							};
							content: {
								__typename: 'ArticleContent';
								articleType: Types.ArticleType;
								snippet?: string | null;
								subtitle?: string | null;
								streamedOn: Array<string>;
								updatedAt: any;
							};
						}>;
					};
			  }
			| { __typename?: 'MultiStepSportEvent'; id: string }
			| { __typename?: 'Offer'; id: string }
			| { __typename?: 'Package'; id: string }
			| { __typename?: 'Page'; id: string }
			| { __typename?: 'Person'; id: string }
			| {
					__typename?: 'Season';
					id: string;
					content: {
						__typename?: 'SeasonContent';
						articles: Array<{
							__typename?: 'Article';
							id: string;
							author?: {
								__typename?: 'Author';
								id: string;
								content: {
									__typename?: 'AuthorContent';
									bio: string;
									clearName: string;
									imageUrl?: string | null;
									fullPath: string;
									alumniOf: Array<string>;
									brand: string;
									languagesSpoken: Array<any>;
									occupation: Array<string>;
									publications?: Array<{
										__typename?: 'AuthorSpecificLink';
										name: string;
										url: string;
									}> | null;
									socialHandles?: Array<{
										__typename?: 'AuthorSpecificLink';
										name: string;
										url: string;
									}> | null;
								};
							};
							content: {
								__typename: 'ArticleContent';
								articleType: Types.ArticleType;
								snippet?: string | null;
								subtitle?: string | null;
								streamedOn: Array<string>;
								updatedAt: any;
							};
						}>;
					};
			  }
			| {
					__typename?: 'Show';
					id: string;
					content: {
						__typename?: 'ShowContent';
						articles: Array<{
							__typename?: 'Article';
							id: string;
							author?: {
								__typename?: 'Author';
								id: string;
								content: {
									__typename?: 'AuthorContent';
									bio: string;
									clearName: string;
									imageUrl?: string | null;
									fullPath: string;
									alumniOf: Array<string>;
									brand: string;
									languagesSpoken: Array<any>;
									occupation: Array<string>;
									publications?: Array<{
										__typename?: 'AuthorSpecificLink';
										name: string;
										url: string;
									}> | null;
									socialHandles?: Array<{
										__typename?: 'AuthorSpecificLink';
										name: string;
										url: string;
									}> | null;
								};
							};
							content: {
								__typename: 'ArticleContent';
								articleType: Types.ArticleType;
								snippet?: string | null;
								subtitle?: string | null;
								streamedOn: Array<string>;
								updatedAt: any;
							};
						}>;
					};
			  }
			| { __typename?: 'SingleStepSportEvent'; id: string }
			| { __typename?: 'SportCompetitionV2'; id: string }
			| { __typename?: 'SportCompetitorV2'; id: string }
			| { __typename?: 'Url'; id: string };
	};
};

export type TitleDetailSnippetFragment = {
	__typename?: 'Article';
	id: string;
	author?: {
		__typename?: 'Author';
		id: string;
		content: {
			__typename?: 'AuthorContent';
			bio: string;
			clearName: string;
			imageUrl?: string | null;
			fullPath: string;
			alumniOf: Array<string>;
			brand: string;
			languagesSpoken: Array<any>;
			occupation: Array<string>;
			publications?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
			socialHandles?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
		};
	};
	content: {
		__typename: 'ArticleContent';
		articleType: Types.ArticleType;
		snippet?: string | null;
		subtitle?: string | null;
		streamedOn: Array<string>;
		updatedAt: any;
	};
};

export const TitleDetailSnippetFragmentDoc = gql`
	fragment TitleDetailSnippet on Article {
		id
		author @include(if: $fetchAuthor) {
			id
			content(country: $country, language: $language) {
				...AuthorData
			}
		}
		content {
			__typename
			articleType
			snippet
			subtitle
			streamedOn
			updatedAt
		}
	}
	${AuthorDataFragmentDoc}
`;
export const GetTitleDetailSnippetsDocument = gql`
	query GetTitleDetailSnippets(
		$fullPath: String!
		$country: Country!
		$language: Language!
		$fetchAuthor: Boolean = true
	) {
		urlV2(fullPath: $fullPath) {
			id
			node {
				id
				... on MovieOrShowOrSeason {
					content(country: $country, language: $language) {
						articles(articleTypes: REVIEW_ARTICLE) {
							...TitleDetailSnippet
						}
					}
				}
			}
		}
	}
	${TitleDetailSnippetFragmentDoc}
`;

import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type TrailerClipsFragment = {
	__typename?: 'Clip';
	sourceUrl: string;
	externalId: string;
	provider: Types.ClipProvider;
	name: string;
};

export const TrailerClipsFragmentDoc = gql`
	fragment TrailerClips on Clip {
		sourceUrl
		externalId
		provider
		name
	}
`;

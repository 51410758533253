declare global {
	interface Window {
		clarity: any;
	}
}

let clarityScriptState: null | Promise<void> = null;
const microsoftClarityTags = new Set<string>();

export async function loadMicrosoftClarity() {
	// Do not load Clarity script on server side
	if (process.server) return null;

	if (clarityScriptState) return clarityScriptState;

	// Initialize Clarity tracking function if not already initialized
	window['clarity'] =
		window['clarity'] ||
		function () {
			// eslint-disable-next-line prefer-rest-params
			(window['clarity'].q = window['clarity'].q || []).push(arguments);
		};

	const clarityScript = document.createElement('script');
	clarityScript.async = true;
	clarityScript.src = `https://www.clarity.ms/tag/ffbppisl6e`;

	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag?.parentNode?.insertBefore(clarityScript, firstScriptTag);
	clarityScriptState = new Promise(resolve => {
		clarityScript.onload = () => {
			resolve();
		};
	});

	return clarityScriptState;
}

export function setClarityCustomTag(customTag: { key: string; value: string }) {
	const tag = customTag.key + '-' + customTag.value;
	if (window?.clarity && !microsoftClarityTags.has(tag)) {
		window.clarity('set', customTag.key, customTag.value);
		microsoftClarityTags.add(tag);
	}
}

import type { SnowplowContext } from '@/helpers/tracking/providers';
import type { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';

import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

export interface ShowAlertPayload {
	header: string;
	subHeader?: string;
	message?: string;
	buttons: string[];
	options?: Object;
	additionalContexts?: SnowplowContext[];
}

export interface ShowPromptPayload {
	header: string;
	subHeader?: string;
	message?: string;
	buttons: AlertButton[];
	options?: Object;
	inputs?: Object[];
}

let aCtr: any = null;

const showAlert = (payload: ShowAlertPayload) => {
	const { additionalContexts = [] } = payload;
	return new Promise(async (resolve, reject) => {
		// return the index of the clicked button or null if the alert has been cancelled
		let clickResult: number | null = null;
		const { header, subHeader = '', message = '', buttons, options = {} } = payload;

		if (!aCtr) {
			const { alertController } = await import('@/ionic.loader');
			aCtr = alertController;
		}

		const alert = await aCtr.create({
			header,
			subHeader,
			message,
			buttons: buttons.map((button, index) => {
				return {
					text: button,
					cssClass: `alert-message__button-${index}`,
					handler: () => {
						clickResult = index;
					},
				};
			}),
			...options,
		});

		alert.present();

		// opening alert
		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'alert_opened',
				nonInteraction: true,
			},
			additionalContexts
		);

		// waiting until the alert is closed
		await alert.onDidDismiss();

		// closing alert
		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'alert_closed',
				label: clickResult + '',
			},
			additionalContexts
		);
		resolve(clickResult);
	});
};

const promptAlert = (payload: ShowPromptPayload) =>
	new Promise(async (resolve, reject) => {
		let clickResult: number | null = null;
		const { header, subHeader = '', inputs, message = '', buttons, options = {} } = payload;

		if (!aCtr) {
			const { alertController } = await import('@/ionic.loader');
			aCtr = alertController;
		}

		const alert = await aCtr.create({
			header,
			subHeader,
			inputs,
			message,
			buttons: buttons.map((button, index) => {
				return {
					...button,
					cssClass: `alert-message__button-${index}`,
					handler: (data: any) => {
						clickResult = index;
						if (button.handler) {
							let hasNotFilledAllInputs = false;
							// Check if user has filled all inputs, and if not, don't remove the prompt.
							Object.values(data).forEach(key => {
								if (key === '') {
									hasNotFilledAllInputs = true;
								}
							});
							if (hasNotFilledAllInputs) {
								return false;
							}
							button?.handler(data);
						}
					},
				};
			}),
			...options,
		});
		alert.present();

		// opening alert
		TrackingHelper.trackEvent('userinteraction', {
			action: 'alert_opened',
			nonInteraction: true,
		});

		// waiting until the alert is closed
		await alert.onDidDismiss();

		// closing alert
		TrackingHelper.trackEvent('userinteraction', {
			action: 'alert_closed',
			label: clickResult + '',
		});
		resolve(clickResult);
	});

export { promptAlert, showAlert };

interface TitleMetaDescriptionsData {
	fullPath: string;
	title: string;
	descriptionNew: string;
	id?: string;
	timestampRequired: boolean;
}

export const titleMetaDescriptionsData: Record<string, TitleMetaDescriptionsData> = {
	'/uk/movie/tiger-3': {
		fullPath: '/uk/movie/tiger-3',
		title: 'Tiger 3 - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Tiger 3 on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		id: 'tm947849',
		timestampRequired: true,
	},
	'/uk/movie/love-actually': {
		fullPath: '/uk/movie/love-actually',
		title: 'Love Actually - movie: watch streaming online',
		descriptionNew:
			'Where to watch Love Actually on streaming services such as Netflix, Prime Video and BBC iPlayer. Streaming options were updated on',
		id: 'tm133767',
		timestampRequired: true,
	},
	'/uk/movie/millers-girl': {
		fullPath: '/uk/movie/millers-girl',
		title: "Miller's Girl - movie: watch streaming online",
		descriptionNew:
			"Where to watch Miller's Girl on streaming services such as Netflix, Prime Video and BBC iPlayer. Streaming options were updated on",
		id: 'tm1409414',
		timestampRequired: true,
	},
	'/uk/movie/bob-marley-one-love': {
		fullPath: '/uk/movie/bob-marley-one-love',
		title: 'Bob Marley: One Love - movie: watch streaming online',
		descriptionNew:
			'Where to watch Bob Marley: One Love on streaming services such as Netflix, Prime Video & BBC iPlayer. This guide was updated on',
		timestampRequired: true,
	},
	'/uk/movie/love': {
		fullPath: '/uk/movie/love',
		title: 'Love - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Love on streaming services such as Netflix, Prime Video & BBC iPlayer today. This guide was updated on',
		id: 'tm138998',
		timestampRequired: true,
	},
	'/uk/movie/one-life-2023': {
		fullPath: '/uk/movie/one-life-2023',
		title: 'One Life - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch One Life on streaming services such as Netflix, Prime Video & BBC iPlayer. All streaming options were updated on',
		id: 'tm1369098',
		timestampRequired: true,
	},
	'/uk/movie/anatomy-of-a-fall': {
		fullPath: '/uk/movie/anatomy-of-a-fall',
		title: 'Anatomy of a Fall - movie: watch streaming online',
		descriptionNew:
			'Find where to watch Anatomy of a Fall on streaming services such as Netflix, Prime Video & BBC iPlayer. This guide was updated on',
		id: 'tm1337628',
		timestampRequired: true,
	},
	'/uk/movie/the-polar-express': {
		fullPath: '/uk/movie/the-polar-express',
		title: 'The Polar Express - movie: watch streaming online',
		descriptionNew:
			'Where to watch The Polar Express on streaming services such as Netflix, Prime Video and BBC iPlayer. This guide was updated on',
		id: 'tm78713',
		timestampRequired: true,
	},
	'/uk/movie/animal-2022': {
		fullPath: '/uk/movie/animal-2022',
		title: 'Animal - movie: where to watch streaming online',
		descriptionNew:
			'Is "Animal" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm1129798',
		timestampRequired: false,
	},
	'/uk/movie/the-second-wife': {
		fullPath: '/uk/movie/the-second-wife',
		title: 'The Second Wife - movie: watch streaming online',
		descriptionNew:
			'Is "The Second Wife" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm55108',
		timestampRequired: false,
	},
	'/uk/movie/past-lives': {
		fullPath: '/uk/movie/past-lives',
		title: 'Past Lives - movie: where to watch streaming online',
		descriptionNew:
			'Is "Past Lives" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm1294945',
		timestampRequired: false,
	},
	'/uk/movie/girl-in-the-basement': {
		fullPath: '/uk/movie/girl-in-the-basement',
		title: 'Girl in the Basement - movie: watch streaming online',
		descriptionNew:
			'Is "Girl in the Basement" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm1019006',
		timestampRequired: false,
	},
	'/uk/movie/dune-2020': {
		fullPath: '/uk/movie/dune-2020',
		title: 'Dune - movie: where to watch streaming online',
		descriptionNew:
			'Is "Dune" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm305113',
		timestampRequired: false,
	},
	'/uk/movie/home-alone': {
		fullPath: '/uk/movie/home-alone',
		title: 'Home Alone - movie: where to watch streaming online',
		descriptionNew:
			'Is "Home Alone" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm155184',
		timestampRequired: false,
	},
	'/uk/movie/interstellar': {
		fullPath: '/uk/movie/interstellar',
		title: 'Interstellar - movie: where to watch streaming online',
		descriptionNew:
			'Is "Interstellar" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm157099',
		timestampRequired: false,
	},
	'/uk/movie/the-zone-of-interest': {
		fullPath: '/uk/movie/the-zone-of-interest',
		title: 'The Zone of Interest - movie: watch streaming online',
		descriptionNew:
			'Is "The Zone of Interest" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm327806',
		timestampRequired: false,
	},
	'/uk/movie/the-holdovers': {
		fullPath: '/uk/movie/the-holdovers',
		title: 'The Holdovers - movie: watch streaming online',
		descriptionNew:
			'Is "The Holdovers" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm1261131',
		timestampRequired: false,
	},
	'/uk/movie/the-beekeeper': {
		fullPath: '/uk/movie/the-beekeeper',
		title: 'The Beekeeper - movie: watch streaming online',
		descriptionNew:
			'Is "The Beekeepers" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm180207',
		timestampRequired: false,
	},
	'/uk/movie/fast-and-furious-10': {
		fullPath: '/uk/movie/fast-and-furious-10',
		title: 'Fast X - movie: where to watch streaming online',
		descriptionNew:
			'Is "Fast X" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		id: 'tm311752',
		timestampRequired: false,
	},
	'/uk/movie/dunki': {
		fullPath: '/uk/movie/dunki',
		title: 'Dunki - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Dunki" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm1216571',
		timestampRequired: false,
	},
	'/uk/movie/the-hunger-games': {
		fullPath: '/uk/movie/the-hunger-games',
		title: 'The Hunger Games - movie: watch streaming online',
		descriptionNew:
			'WATCH "The Hunger Games" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm57536',
		timestampRequired: false,
	},
	'/uk/movie/sam-bahadur': {
		fullPath: '/uk/movie/sam-bahadur',
		title: 'Sam Bahadur - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Sam Bahadur" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm829786',
		timestampRequired: false,
	},
	'/uk/movie/charlottes-net': {
		fullPath: '/uk/movie/charlottes-net',
		title: "Charlotte's Net - movie: watch streaming online",
		descriptionNew:
			'WATCH "Charlotte\'s Net" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm964979',
		timestampRequired: false,
	},
	'/uk/movie/twilight': {
		fullPath: '/uk/movie/twilight',
		title: 'Twilight - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Twilight" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm57772',
		timestampRequired: false,
	},
	'/uk/movie/five-nights-at-freddys': {
		fullPath: '/uk/movie/five-nights-at-freddys',
		title: "Five Nights at Freddy's - watch streaming online",
		descriptionNew:
			"WATCH \"Five Night's at Freddy's\" online now with JustWatch's complete streaming guide: Find where to watch it for free, how to stream in 4K & more.",
		id: 'tm428148',
		timestampRequired: false,
	},
	'/uk/movie/no-way-up': {
		fullPath: '/uk/movie/no-way-up',
		title: 'No Way Up - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "No Way Up" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm1342837',
		timestampRequired: false,
	},
	'/uk/movie/bottoms': {
		fullPath: '/uk/movie/bottoms',
		title: 'Bottoms - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Bottoms" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm1303915',
		timestampRequired: false,
	},
	'/uk/movie/spirited-away': {
		fullPath: '/uk/movie/spirited-away',
		title: 'Spirited Away - movie: watch streaming online',
		descriptionNew:
			'WATCH "Spirited Away" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm48297',
		timestampRequired: false,
	},
	'/uk/movie/willy-wonka-and-the-chocolate-factory': {
		fullPath: '/uk/movie/willy-wonka-and-the-chocolate-factory',
		title: 'Willy Wonka & the Chocolate Factory - streaming',
		descriptionNew:
			'WATCH "Willy Wonka & the Chocolate Factory" online now with JustWatch\'s complete streaming guide: Find where to watch it for free and how to stream in 4K.',
		id: 'tm15070',
		timestampRequired: false,
	},
	'/uk/movie/migration': {
		fullPath: '/uk/movie/migration',
		title: 'Migration - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Migration" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		id: 'tm1172801',
		timestampRequired: false,
	},
	'/uk/movie/national-lampoons-christmas-vacation': {
		fullPath: '/uk/movie/national-lampoons-christmas-vacation',
		title: "National Lampoon's Christmas Vacation - streaming",
		descriptionNew:
			'Where to watch "National Lampoon\'s Christmas Vacation" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		id: 'tm147710',
		timestampRequired: false,
	},
	'/uk/movie/wish-2023': {
		fullPath: '/uk/movie/wish-2023',
		title: 'Wish - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Wish" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm1254240',
		timestampRequired: false,
	},
	'/uk/movie/spider-man-into-the-spider-verse-2': {
		fullPath: '/uk/movie/spider-man-into-the-spider-verse-2',
		title: 'Spider-Man: Across the Spider-Verse - streaming',
		descriptionNew:
			'Where to watch "Spider-Man: Across the Spider-Verse" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		id: 'tm949044',
		timestampRequired: false,
	},
	'/uk/movie/below-her-mouth': {
		fullPath: '/uk/movie/below-her-mouth',
		title: 'Below Her Mouth - movie: watch streaming online',
		descriptionNew:
			'Where to watch "Below Her Mouth" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm233503',
		timestampRequired: false,
	},
	'/uk/movie/gremlins': {
		fullPath: '/uk/movie/gremlins',
		title: 'Gremlins - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Gremlins" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm173440',
		timestampRequired: false,
	},
	'/uk/movie/solar': {
		fullPath: '/uk/movie/solar',
		title: 'Solar - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Solar" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm229898',
		timestampRequired: false,
	},
	'/uk/movie/charlie-and-the-chocolate-factory': {
		fullPath: '/uk/movie/charlie-and-the-chocolate-factory',
		title: 'Charlie and the Chocolate Factory - streaming',
		descriptionNew:
			'Where to watch "Charlie and the Chocolate Factory" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		id: 'tm160815',
		timestampRequired: false,
	},
	'/uk/movie/killers-of-the-flower-moon': {
		fullPath: '/uk/movie/killers-of-the-flower-moon',
		title: 'Killers of the Flower Moon - watch streaming online',
		descriptionNew:
			'Where to watch "Killers of the Flower Moon" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm327233',
		timestampRequired: false,
	},
	'/uk/movie/virgin-territory': {
		fullPath: '/uk/movie/virgin-territory',
		title: 'Virgin Territory - movie: watch streaming online',
		descriptionNew:
			'Where to watch "Virgin Territory" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm77607',
		timestampRequired: false,
	},
	'/uk/movie/dream-scenario': {
		fullPath: '/uk/movie/dream-scenario',
		title: 'Dream Scenario - movie: watch streaming online',
		descriptionNew:
			'Where to watch "Dream Scenario" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm1293137',
		timestampRequired: false,
	},
	'/uk/movie/a-traves-de-tu-mirada': {
		fullPath: '/uk/movie/a-traves-de-tu-mirada',
		title: 'Through My Window 3: Looking at You - streaming',
		descriptionNew:
			'Where to watch "Through My Window 3: Looking at You" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		id: 'tm1419001',
		timestampRequired: false,
	},
	'/uk/movie/365-dni': {
		fullPath: '/uk/movie/365-dni',
		title: '365 Days - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "365 Days" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm832345',
		timestampRequired: false,
	},
	'/uk/movie/the-iron-claw': {
		fullPath: '/uk/movie/the-iron-claw',
		title: 'The Iron Claw - movie: watch streaming online',
		descriptionNew:
			'Where to watch "The Iron Claw" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm1367018',
		timestampRequired: false,
	},
	'/uk/movie/ferrari-2023': {
		fullPath: '/uk/movie/ferrari-2023',
		title: 'Ferrari - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Ferrari" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm231790',
		timestampRequired: false,
	},
	'/uk/movie/nativity': {
		fullPath: '/uk/movie/nativity',
		title: 'Nativity! - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Nativity!" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm138604',
		timestampRequired: false,
	},
	'/uk/movie/arthur-christmas': {
		fullPath: '/uk/movie/arthur-christmas',
		title: 'Arthur Christmas - movie: watch streaming online',
		descriptionNew:
			'Where to watch "Arthur Christmas" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm174683',
		timestampRequired: false,
	},
	'/uk/movie/the-equalizer-3': {
		fullPath: '/uk/movie/the-equalizer-3',
		title: 'The Equalizer 3 - movie: watch streaming online',
		descriptionNew:
			'Where to watch "The Equalizer 3" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm1198657',
		timestampRequired: false,
	},
	'/uk/movie/the-legend-of-maula-jatt': {
		fullPath: '/uk/movie/the-legend-of-maula-jatt',
		title: 'The Legend Of Maula Jatt - watch streaming online',
		descriptionNew:
			'Where to watch "The Legend of Maula Jatt" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		id: 'tm336391',
		timestampRequired: false,
	},
	'/uk/movie/the-meg-2-the-trench': {
		fullPath: '/uk/movie/the-meg-2-the-trench',
		title: 'Meg 2: The Trench - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Meg 2: The Trench on streaming services such as Netflix, Prime Video & BBC iPlayer. This guide was updated on',
		timestampRequired: true,
	},
	'/uk/movie/road-house-2024': {
		fullPath: '/uk/movie/road-house-2024',
		title: 'Road House - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Road House on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/blackberry': {
		fullPath: '/uk/movie/blackberry',
		title: 'Blackberry - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Blackberry on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/talk-to-me-2023': {
		fullPath: '/uk/movie/talk-to-me-2023',
		title: 'Talk to Me - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Talk to Me on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/top-gun-maverick': {
		fullPath: '/uk/movie/top-gun-maverick',
		title: 'Top Gun: Maverick - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Top Gun: Maverick on streaming services such as Netflix, Prime Video & BBC iPlayer. Last updated on',
		timestampRequired: true,
	},
	'/uk/movie/indiana-jones-5': {
		fullPath: '/uk/movie/indiana-jones-5',
		title: 'Indiana Jones and the Dial of Destiny - movie: watch online',
		descriptionNew:
			'Where to watch Indiana Jones and the Dial of Destiny on streaming services such as Netflix, Prime Video & BBC iPlayer. Last update:',
		timestampRequired: true,
	},
	'/uk/movie/12th-fail': {
		fullPath: '/uk/movie/12th-fail',
		title: '12th Fail - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch 12th Fail on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/john-wick': {
		fullPath: '/uk/movie/john-wick',
		title: 'John Wick - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch John Wick on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/talk-to-me-2022': {
		fullPath: '/uk/movie/talk-to-me-2022',
		title: 'Talk to Me - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Talk to Me on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/the-gentlemen': {
		fullPath: '/uk/movie/the-gentlemen',
		title: 'The Gentlemen - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch The Gentlemen on streaming services such as Netflix, Prime Video & BBC iPlayer. All streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/gomburza': {
		fullPath: '/uk/movie/gomburza',
		title: 'GomBurZa - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch GomBurZa on streaming services such as Netflix, Prime Video & BBC iPlayer. All streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/aftersun-2022': {
		fullPath: '/uk/movie/aftersun-2022',
		title: 'Aftersun - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Aftersun on streaming services such as Netflix, Prime Video & BBC iPlayer. All streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/the-hunger-games-the-ballad-of-songbirds-and-snakes': {
		fullPath: '/uk/movie/the-hunger-games-the-ballad-of-songbirds-and-snakes',
		title: 'Watch The Hunger Games: The Ballad of Songbirds & Snakes',
		descriptionNew:
			'Find where to watch The Hunger Games: The Ballad of Songbirds & Snakes on streaming services. All streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/flashpoint': {
		fullPath: '/uk/movie/flashpoint',
		title: 'The Flash - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch The Flash on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/wicked-little-letters': {
		fullPath: '/uk/movie/wicked-little-letters',
		title: 'Wicked Little Letters - movie: watch streaming online',
		descriptionNew:
			'Where to watch Wicked Little Letters on streaming services such as Netflix, Prime Video and BBC iPlayer. This guide was updated on',
		timestampRequired: true,
	},
	'/uk/movie/how-to-have-sex': {
		fullPath: '/uk/movie/how-to-have-sex',
		title: 'How to Have Sex - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch How to Have Sex on streaming services such as Netflix, Prime Video and BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/mean-girls': {
		fullPath: '/uk/movie/mean-girls',
		title: 'Mean Girls - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Mean Girls on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/guy-ritchies-the-covenant': {
		fullPath: '/uk/movie/guy-ritchies-the-covenant',
		title: "Guy Ritchie's The Covenant - movie: watch streaming online",
		descriptionNew:
			"Where to watch Guy Ritchie's The Covenant on streaming services such as Netflix, Prime Video & BBC iPlayer. Last updated on",
		timestampRequired: true,
	},
	'/uk/movie/no-one-will-save-you': {
		fullPath: '/uk/movie/no-one-will-save-you',
		title: 'No One Will Save You - movie: watch streaming online',
		descriptionNew:
			'Find where to watch No One Will Save You on streaming services such as Netflix, Prime Video & BBC iPlayer. Last updated on',
		timestampRequired: true,
	},
	'/uk/movie/m3gan': {
		fullPath: '/uk/movie/m3gan',
		title: 'M3GAN - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch M3GAN on streaming services such as Netflix, Prime Video & BBC iPlayer. All streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/cocaine-bear': {
		fullPath: '/uk/movie/cocaine-bear',
		title: 'Cocaine Bear - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Cocaine Bear on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/bullet-train': {
		fullPath: '/uk/movie/bullet-train',
		title: 'Bullet Train - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Bullet Train on streaming services such as Netflix, Prime Video & BBC iPlayer. Streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/transformers-rise-of-the-beasts-2023': {
		fullPath: '/uk/movie/transformers-rise-of-the-beasts-2023',
		title: 'Transformers: Rise of the Beasts - movie: watch online',
		descriptionNew:
			'Where to watch Transformers: Rise of the Beasts on streaming services such as Netflix, Prime Video & BBC iPlayer. Last updated on',
		timestampRequired: true,
	},
	'/uk/movie/babylon-2022': {
		fullPath: '/uk/movie/babylon-2022',
		title: 'Babylon - movie: where to watch streaming online',
		descriptionNew:
			'Find where to watch Babylon on streaming services such as Netflix, Prime Video & BBC iPlayer. All streaming options were updated on',
		timestampRequired: true,
	},
	'/uk/movie/spider-man-no-way-home': {
		fullPath: '/uk/movie/spider-man-no-way-home',
		title: 'Spider-Man: No Way Home - movie: watch streaming online',
		descriptionNew:
			'Where to watch Spider-Man: No Way Home on streaming services such as Netflix, Prime Video and BBC iPlayer. Last updated on',
		timestampRequired: true,
	},
	'/uk/movie/the-whale': {
		fullPath: '/uk/movie/the-whale',
		title: 'The Whale - movie: where to watch streaming online',
		descriptionNew:
			'Is "The Whale" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/titanic-1997': {
		fullPath: '/uk/movie/titanic-1997',
		title: 'Titanic - movie: where to watch streaming online',
		descriptionNew:
			'Is "Titanic" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/late-night-with-the-devil': {
		fullPath: '/uk/movie/late-night-with-the-devil',
		title: 'Late Night with the Devil - movie: watch streaming online',
		descriptionNew:
			'Is "Late Night with the Devil" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/everything-everywhere-all-at-once': {
		fullPath: '/uk/movie/everything-everywhere-all-at-once',
		title: 'Everything Everywhere All at Once - movie: watch online',
		descriptionNew:
			'Is "Everything Everywhere All at Once" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/shrek': {
		fullPath: '/uk/movie/shrek',
		title: 'Shrek - movie: where to watch streaming online',
		descriptionNew:
			'Is "Shrek" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/the-godfather': {
		fullPath: '/uk/movie/the-godfather',
		title: 'The Godfather - movie: where to watch streaming online',
		descriptionNew:
			'Is "The Godfather" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/avatar-2': {
		fullPath: '/uk/movie/avatar-2',
		title: 'Avatar: The Way of Water - movie: watch streaming online',
		descriptionNew:
			'Is "Avatar: The Way of Water" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/blade-runner-2049': {
		fullPath: '/uk/movie/blade-runner-2049',
		title: 'Blade Runner 2049 - movie: where to watch streaming online',
		descriptionNew:
			'Is "Blade Runner 2049" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/society-of-the-snow': {
		fullPath: '/uk/movie/society-of-the-snow',
		title: 'Society of the Snow - movie: where to watch streaming online',
		descriptionNew:
			'Is "Society of the Snow" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/are-you-there-god-its-me-margaret': {
		fullPath: '/uk/movie/are-you-there-god-its-me-margaret',
		title: "Are You There God? It's Me, Margaret. - movie: watch online",
		descriptionNew:
			'Is "Are You There God? It\'s Me, Margaret." streaming on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/pearl-2022': {
		fullPath: '/uk/movie/pearl-2022',
		title: 'Pearl - movie: where to watch streaming online',
		descriptionNew:
			'Is "Pearl" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/puss-in-boots-2-nine-lives-and-40-thieves': {
		fullPath: '/uk/movie/puss-in-boots-2-nine-lives-and-40-thieves',
		title: 'Puss in Boots: The Last Wish - movie: watch streaming online',
		descriptionNew:
			'Is "Puss in Boots: The Last Wish" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/inception': {
		fullPath: '/uk/movie/inception',
		title: 'Inception - movie: where to watch streaming online',
		descriptionNew:
			'Is "Inception" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/mission-impossible': {
		fullPath: '/uk/movie/mission-impossible',
		title: 'Mission: Impossible - movie: where to watch streaming online',
		descriptionNew:
			'Is "Mission: Impossible" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/a-man-called-otto': {
		fullPath: '/uk/movie/a-man-called-otto',
		title: 'A Man Called Otto - movie: where to watch streaming online',
		descriptionNew:
			'Is "A Man Called Otto" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/evil-dead-rise': {
		fullPath: '/uk/movie/evil-dead-rise',
		title: 'Evil Dead Rise - movie: where to watch streaming online',
		descriptionNew:
			'Is "Evil Dead Rise" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/rocky-aur-rani-ki-prem-kahani': {
		fullPath: '/uk/movie/rocky-aur-rani-ki-prem-kahani',
		title: 'Rocky Aur Rani Kii Prem Kahaani - movie: watch online',
		descriptionNew:
			'Is "Rocky Aur Rani Kii Prem Kahaani" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/a-haunting-in-venice': {
		fullPath: '/uk/movie/a-haunting-in-venice',
		title: 'A Haunting in Venice - movie: watch streaming online',
		descriptionNew:
			'Is "A Haunting in Venice" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/the-killer-2023-0': {
		fullPath: '/uk/movie/the-killer-2023-0',
		title: 'The Killer - movie: where to watch streaming online',
		descriptionNew:
			'Is "The Killer" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/harry-potter-and-the-philosophers-stone': {
		fullPath: '/uk/movie/harry-potter-and-the-philosophers-stone',
		title: "Harry Potter and the Philosopher's Stone - watch online",
		descriptionNew:
			'Is "Harry Potter and the Philosopher\'s Stone" streaming on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/rise-of-the-footsoldier-vengeance': {
		fullPath: '/uk/movie/rise-of-the-footsoldier-vengeance',
		title: 'Rise of the Footsoldier: Vengeance - movie: watch online',
		descriptionNew:
			'Is "Rise of the Footsoldier: Vengeance" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/tenet': {
		fullPath: '/uk/movie/tenet',
		title: 'Tenet - movie: where to watch streaming online',
		descriptionNew:
			'Is "Tenet" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/coraline': {
		fullPath: '/uk/movie/coraline',
		title: 'Coraline - movie: where to watch streaming online',
		descriptionNew:
			'Is "Coraline" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/nope': {
		fullPath: '/uk/movie/nope',
		title: 'Nope - movie: where to watch streaming online',
		descriptionNew:
			'Is "Nope" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/jawan': {
		fullPath: '/uk/movie/jawan',
		title: 'Jawan - movie: where to watch streaming online',
		descriptionNew:
			'Is "Jawan" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/the-matrix': {
		fullPath: '/uk/movie/the-matrix',
		title: 'The Matrix - movie: where to watch streaming online',
		descriptionNew:
			'Is "The Matrix" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/may-december': {
		fullPath: '/uk/movie/may-december',
		title: 'May December - movie: where to watch streaming online',
		descriptionNew:
			'Is "May December" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/la-la-land': {
		fullPath: '/uk/movie/la-la-land',
		title: 'La La Land - movie: where to watch streaming online',
		descriptionNew:
			'Is "La La Land" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/the-thundermans-return': {
		fullPath: '/uk/movie/the-thundermans-return',
		title: 'The Thundermans Return - movie: watch streaming online',
		descriptionNew:
			'Is "The Thundermans Return" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/triangle-of-sadness': {
		fullPath: '/uk/movie/triangle-of-sadness',
		title: 'Triangle of Sadness - movie: where to watch streaming online',
		descriptionNew:
			'Is "Triangle of Sadness" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/the-nice-guys': {
		fullPath: '/uk/movie/the-nice-guys',
		title: 'The Nice Guys - movie: where to watch streaming online',
		descriptionNew:
			'Is "The Nice Guys" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/jurassic-park': {
		fullPath: '/uk/movie/jurassic-park',
		title: 'Jurassic Park - movie: where to watch streaming online',
		descriptionNew:
			'Is "Jurassic Park" available to stream on Netflix, Prime Video, BBC iPlayer or any other streaming service? Find out with JustWatch.',
		timestampRequired: false,
	},
	'/uk/movie/ghostbusters-iii': {
		fullPath: '/uk/movie/ghostbusters-iii',
		title: 'Ghostbusters: Afterlife - movie: watch streaming online',
		descriptionNew:
			'WATCH "Ghostbusters: Afterlife" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/leave-the-world-behind-2023': {
		fullPath: '/uk/movie/leave-the-world-behind-2023',
		title: 'Leave the World Behind - movie: watch streaming online',
		descriptionNew:
			'WATCH "Leave the World Behind" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/the-prestige': {
		fullPath: '/uk/movie/the-prestige',
		title: 'The Prestige - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "The Prestige" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/hot-fuzz': {
		fullPath: '/uk/movie/hot-fuzz',
		title: 'Hot Fuzz - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Hot Fuzz" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/the-dark-knight': {
		fullPath: '/uk/movie/the-dark-knight',
		title: 'The Dark Knight - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "The Dark Knight" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/kung-fu-panda': {
		fullPath: '/uk/movie/kung-fu-panda',
		title: 'Kung Fu Panda - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Kung Fu Panda" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/after-everything-2023': {
		fullPath: '/uk/movie/after-everything-2023',
		title: 'After Everything - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "After Everything" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/the-shawshank-redemption': {
		fullPath: '/uk/movie/the-shawshank-redemption',
		title: 'The Shawshank Redemption - movie: watch streaming online',
		descriptionNew:
			'WATCH "The Shawshank Redemption" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/fantastic-beasts-3': {
		fullPath: '/uk/movie/fantastic-beasts-3',
		title: 'Fantastic Beasts: The Secrets of Dumbledore - watch online',
		descriptionNew:
			'WATCH "Fantastic Beasts: The Secrets of Dumbledore" online now with JustWatch\'s streaming guide: Find where to watch it for free and how to stream in 4K.',
		timestampRequired: false,
	},
	'/uk/movie/dont-worry-darling': {
		fullPath: '/uk/movie/dont-worry-darling',
		title: "Don't Worry Darling - movie: watch streaming online",
		descriptionNew:
			'WATCH "Don\'t Worry Darling" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/air-2023': {
		fullPath: '/uk/movie/air-2023',
		title: 'Air - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Air" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/die-hard': {
		fullPath: '/uk/movie/die-hard',
		title: 'Die Hard - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Die Hard" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/the-family-plan': {
		fullPath: '/uk/movie/the-family-plan',
		title: 'The Family Plan - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "The Family Plan" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/american-psycho': {
		fullPath: '/uk/movie/american-psycho',
		title: 'American Psycho - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "American Psycho" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/catch-me-if-you-can': {
		fullPath: '/uk/movie/catch-me-if-you-can',
		title: 'Catch Me If You Can - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Catch Me If You Can" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/scott-pilgrim-vs-the-world': {
		fullPath: '/uk/movie/scott-pilgrim-vs-the-world',
		title: 'Scott Pilgrim vs. the World - movie: watch streaming online',
		descriptionNew:
			'WATCH "Scott Pilgrim vs. the World" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/the-menu': {
		fullPath: '/uk/movie/the-menu',
		title: 'The Menu - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "The Menu" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/the-wolf-of-wall-street': {
		fullPath: '/uk/movie/the-wolf-of-wall-street',
		title: 'The Wolf of Wall Street - movie: watch streaming online',
		descriptionNew:
			'WATCH "The Wolf of Wall Street" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/gran-turismo': {
		fullPath: '/uk/movie/gran-turismo',
		title: 'Gran Turismo - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Gran Turismo" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/boiling-point-2021': {
		fullPath: '/uk/movie/boiling-point-2021',
		title: 'Boiling Point - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Boiling Point" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/tar': {
		fullPath: '/uk/movie/tar',
		title: 'TÁR - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "TÁR" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/parasite': {
		fullPath: '/uk/movie/parasite',
		title: 'Parasite - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Parasite" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/possession-1981': {
		fullPath: '/uk/movie/possession-1981',
		title: 'Possession - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Possession" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/mission-impossible-fallout': {
		fullPath: '/uk/movie/mission-impossible-fallout',
		title: 'Mission: Impossible - Fallout - movie: watch online',
		descriptionNew:
			'WATCH "Mission: Impossible - Fallout" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/x-2022': {
		fullPath: '/uk/movie/x-2022',
		title: 'X - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "X" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/beetlejuice': {
		fullPath: '/uk/movie/beetlejuice',
		title: 'Beetlejuice - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Beetlejuice" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/red-white-and-royal-blue': {
		fullPath: '/uk/movie/red-white-and-royal-blue',
		title: 'Red, White & Royal Blue - movie: watch streaming online',
		descriptionNew:
			'WATCH "Red, White & Royal Blue" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/civil-war-2024-0': {
		fullPath: '/uk/movie/civil-war-2024-0',
		title: 'Civil War - movie: where to watch streaming online',
		descriptionNew:
			'WATCH "Civil War" online now with JustWatch\'s complete streaming guide: Find where to watch it for free, how to stream in 4K & more.',
		timestampRequired: false,
	},
	'/uk/movie/blade-runner': {
		fullPath: '/uk/movie/blade-runner',
		title: 'Blade Runner - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Blade Runner" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/untitled-scream-sequel': {
		fullPath: '/uk/movie/untitled-scream-sequel',
		title: 'Scream VI - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Scream VI" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/the-terminator': {
		fullPath: '/uk/movie/the-terminator',
		title: 'The Terminator - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Terminator" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/mamma-mia': {
		fullPath: '/uk/movie/mamma-mia',
		title: 'Mamma Mia! - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Mamma Mia!" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/john-wick-chapter-3': {
		fullPath: '/uk/movie/john-wick-chapter-3',
		title: 'John Wick: Chapter 3 - Parabellum - movie: watch online',
		descriptionNew:
			'Watch "John Wick: Chapter 3 - Parabellum" online on streaming services such as Netflix, Prime Video and Disney+ today - including 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/a-bronx-tale': {
		fullPath: '/uk/movie/a-bronx-tale',
		title: 'A Bronx Tale - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "A Bronx Tale" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/back-to-the-future': {
		fullPath: '/uk/movie/back-to-the-future',
		title: 'Back to the Future - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Back to the Future" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/the-thing-1982': {
		fullPath: '/uk/movie/the-thing-1982',
		title: 'The Thing - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Thing" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/elvis-2022': {
		fullPath: '/uk/movie/elvis-2022',
		title: 'Elvis - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Elvis" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/renfield': {
		fullPath: '/uk/movie/renfield',
		title: 'Renfield - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Renfield" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/65': {
		fullPath: '/uk/movie/65',
		title: '65 - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "65" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/godzilla-king-of-the-monsters': {
		fullPath: '/uk/movie/godzilla-king-of-the-monsters',
		title: 'Godzilla: King of the Monsters - movie: watch online',
		descriptionNew:
			'Watch "Godzilla: King of the Monsters" online on streaming services such as Netflix, Prime Video and Disney+ today - including 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/dil-diyan-gallan': {
		fullPath: '/uk/movie/dil-diyan-gallan',
		title: 'Dil Diyan Gallan - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Dil Diyan Gallan" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/arrival-2016': {
		fullPath: '/uk/movie/arrival-2016',
		title: 'Arrival - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Arrival" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/20-days-in-mariupol': {
		fullPath: '/uk/movie/20-days-in-mariupol',
		title: '20 Days in Mariupol - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "20 Days in Mariupol" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/ready-player-one': {
		fullPath: '/uk/movie/ready-player-one',
		title: 'Ready Player One - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Ready Player One" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/oldboy-2003': {
		fullPath: '/uk/movie/oldboy-2003',
		title: 'Oldboy - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Oldboy" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/the-taste-of-things': {
		fullPath: '/uk/movie/the-taste-of-things',
		title: 'The Taste of Things - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Taste of Things" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/teenage-mutant-ninja-turtles-the-next-chapter': {
		fullPath: '/uk/movie/teenage-mutant-ninja-turtles-the-next-chapter',
		title: 'Teenage Mutant Ninja Turtles: Mutant Mayhem - watch online',
		descriptionNew:
			'Watch "Teenage Mutant Ninja Turtles: Mutant Mayhem" on streaming services such as Netflix, Prime Video and Disney+ today - including 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/upgraded': {
		fullPath: '/uk/movie/upgraded',
		title: 'Upgraded - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Upgraded" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/farming': {
		fullPath: '/uk/movie/farming',
		title: 'Farming - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Farming" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/beau-is-afraid': {
		fullPath: '/uk/movie/beau-is-afraid',
		title: 'Beau is Afraid - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Beau is Afraid" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/se7en': {
		fullPath: '/uk/movie/se7en',
		title: 'Se7en - movie: where to watch streaming online',
		descriptionNew:
			'Find out how and where to watch "Se7en" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/ford-v-ferrari': {
		fullPath: '/uk/movie/ford-v-ferrari',
		title: 'Ford v Ferrari - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Ford v Ferrari" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/knives-out': {
		fullPath: '/uk/movie/knives-out',
		title: 'Knives Out - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Knives Out" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/batman-begins': {
		fullPath: '/uk/movie/batman-begins',
		title: 'Batman Begins - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Batman Begins" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/goodfellas': {
		fullPath: '/uk/movie/goodfellas',
		title: 'Goodfellas - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Goodfellas" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/the-equalizer-2': {
		fullPath: '/uk/movie/the-equalizer-2',
		title: 'The Equalizer 2 - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Equalizer 2" online on streaming services such as Netflix, Prime Video and Disney+ today - including all 4K and free options.',
		timestampRequired: false,
	},
	'/uk/movie/promising-young-woman': {
		fullPath: '/uk/movie/promising-young-woman',
		title: 'Promising Young Woman - movie: watch streaming online',
		descriptionNew:
			'Where to watch "Promising Young Woman" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/reality-2023': {
		fullPath: '/uk/movie/reality-2023',
		title: 'Reality - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Reality" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/femme-2024': {
		fullPath: '/uk/movie/femme-2024',
		title: 'Femme - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Femme" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/casino': {
		fullPath: '/uk/movie/casino',
		title: 'Casino - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Casino" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/rye-lane': {
		fullPath: '/uk/movie/rye-lane',
		title: 'Rye Lane - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Rye Lane" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/the-fabelmans': {
		fullPath: '/uk/movie/the-fabelmans',
		title: 'The Fabelmans - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Fabelmans" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/the-little-mermaid-2021': {
		fullPath: '/uk/movie/the-little-mermaid-2021',
		title: 'The Little Mermaid - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Little Mermaid" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/mean-girls-2024': {
		fullPath: '/uk/movie/mean-girls-2024',
		title: 'Mean Girls - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Mean Girls" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/pulp-fiction': {
		fullPath: '/uk/movie/pulp-fiction',
		title: 'Pulp Fiction - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Pulp Fiction" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/dumb-money': {
		fullPath: '/uk/movie/dumb-money',
		title: 'Dumb Money - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Dumb Money" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/the-great-escaper': {
		fullPath: '/uk/movie/the-great-escaper',
		title: 'The Great Escaper - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Great Escaper" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/the-banshees-of-inisherin': {
		fullPath: '/uk/movie/the-banshees-of-inisherin',
		title: 'The Banshees of Inisherin - movie: watch streaming online',
		descriptionNew:
			'Where to watch "The Banshees of Inisherin" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/violent-night': {
		fullPath: '/uk/movie/violent-night',
		title: 'Violent Night - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Violent Night" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/the-silence-of-the-lambs': {
		fullPath: '/uk/movie/the-silence-of-the-lambs',
		title: 'The Silence of the Lambs - movie: watch streaming online',
		descriptionNew:
			'Where to watch "The Silence of the Lambs" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/blood-and-snow': {
		fullPath: '/uk/movie/blood-and-snow',
		title: 'Blood and Snow - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Blood and Snow" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/ricky-stanicky': {
		fullPath: '/uk/movie/ricky-stanicky',
		title: 'Ricky Stanicky - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Ricky Stanicky" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/culpa-mia': {
		fullPath: '/uk/movie/culpa-mia',
		title: 'My Fault - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "My Fault" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/untitled-pixar-animation-project-3': {
		fullPath: '/uk/movie/untitled-pixar-animation-project-3',
		title: 'Elemental - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Elemental" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/genie-2023': {
		fullPath: '/uk/movie/genie-2023',
		title: 'Genie - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Genie" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/a-quiet-place': {
		fullPath: '/uk/movie/a-quiet-place',
		title: 'A Quiet Place - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "A Quiet Place" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/train-to-busan': {
		fullPath: '/uk/movie/train-to-busan',
		title: 'Train to Busan - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Train to Busan" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/the-princess-bride': {
		fullPath: '/uk/movie/the-princess-bride',
		title: 'The Princess Bride - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Princess Bride" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/hereditary': {
		fullPath: '/uk/movie/hereditary',
		title: 'Hereditary - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Hereditary" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/no-time-to-die-2020': {
		fullPath: '/uk/movie/no-time-to-die-2020',
		title: 'No Time to Die - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "No Time to Die" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/the-lobster': {
		fullPath: '/uk/movie/the-lobster',
		title: 'The Lobster - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "The Lobster" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/gladiator': {
		fullPath: '/uk/movie/gladiator',
		title: 'Gladiator - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Gladiator" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/midsommar': {
		fullPath: '/uk/movie/midsommar',
		title: 'Midsommar - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "Midsommar" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
	'/uk/movie/fast-and-furious-9': {
		fullPath: '/uk/movie/fast-and-furious-9',
		title: 'F9 - movie: where to watch streaming online',
		descriptionNew:
			'Where to watch "F9" online on streaming services such as Netflix, Prime Video and Disney+ today.',
		timestampRequired: false,
	},
};

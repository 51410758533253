import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetPackageProviderPlansQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	packageIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;

export type GetPackageProviderPlansQuery = {
	__typename?: 'Query';
	packages: Array<{
		__typename?: 'Package';
		id: string;
		clearName: string;
		planOffers: Array<{
			__typename?: 'PackagePlanOffer';
			title?: string | null;
			retailPrice?: string | null;
			isTrial?: boolean | null;
			durationDays?: number | null;
			retailPriceValue?: number | null;
			children: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
			}>;
		}>;
	}>;
};

export const GetPackageProviderPlansDocument = gql`
	query GetPackageProviderPlans($country: Country!, $language: Language!, $packageIds: [Int!]) {
		packages(country: $country, platform: WEB, packageIds: $packageIds) {
			id
			clearName
			planOffers(country: $country, platform: WEB) {
				title
				retailPrice(language: $language)
				isTrial
				durationDays
				retailPriceValue
				children {
					title
					retailPrice(language: $language)
					isTrial
					durationDays
					retailPriceValue
				}
			}
		}
	}
`;

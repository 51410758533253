import { ObjectType } from '@/@types/graphql-types';
import { TitleDetail, TitleDetailSeason, TitleDetailShow } from '@/interfaces/title-details-graphql';
import { computed } from 'vue';
import { toValue, type MaybeRefOrGetter } from '@vueuse/core';

export function isTitleSeason(node?: TitleDetail): node is TitleDetailSeason {
	return node?.__typename === 'Season' && !!node.show;
}

export function isTitleShow(node?: TitleDetail): node is TitleDetailShow {
	return node?.__typename === 'Show';
}

export function useTitleDetailInfo(titleDetailComputed?: MaybeRefOrGetter<TitleDetail | undefined>) {
	const titleDetail = computed(() => toValue(titleDetailComputed));

	const isShowComputed = computed(() => isTitleShow(titleDetail.value));

	const isSeasonComputed = computed(() => isTitleSeason(titleDetail.value));

	const title = computed(() => (isTitleSeason(titleDetail.value) ? titleDetail.value?.show : titleDetail.value));

	const titleName = computed(() =>
		isTitleSeason(titleDetail.value) ? titleDetail.value?.show?.content.title : titleDetail.value?.content.title
	);

	const titleId = computed(() =>
		isTitleSeason(titleDetail.value) ? titleDetail.value?.show?.id : titleDetail.value?.id
	);

	const titleObjectId = computed(() =>
		isTitleSeason(titleDetail.value) ? titleDetail.value?.show?.objectId : titleDetail.value?.objectId
	);

	const titleObjectType = computed(() =>
		isTitleSeason(titleDetail.value)
			? (titleDetail.value?.show.objectType as ObjectType.Show)
			: (titleDetail.value?.objectType as ObjectType.Movie | ObjectType.Show)
	);

	const titlePosterUrl = computed(() =>
		isTitleSeason(titleDetail.value)
			? titleDetail.value?.show?.content.posterUrl
			: titleDetail.value?.content.posterUrl
	);

	const titleBackdrops = computed(() =>
		isTitleSeason(titleDetail.value)
			? titleDetail.value?.show?.content.backdrops
			: titleDetail.value?.content.backdrops
	);

	const seasonName = computed(() => (isTitleSeason(titleDetail.value) ? titleDetail.value?.content.title : null));

	const seasonNumber = computed(() =>
		isTitleSeason(titleDetail.value) ? titleDetail.value?.content.seasonNumber : null
	);

	const seasonEpisodes = computed(() => (isTitleSeason(titleDetail.value) ? titleDetail.value?.episodes ?? [] : []));

	const seasonTotalEpisodeCount = computed(() =>
		isTitleSeason(titleDetail.value) ? titleDetail.value?.totalEpisodeCount : null
	);

	const showSeasonsAvailable = computed(
		() => isTitleShow(titleDetail.value) && (titleDetail.value?.seasons?.length ?? 0) > 0
	);

	const recentEpisodes = computed(() =>
		isTitleShow(titleDetail.value) ? titleDetail.value?.recentEpisodes ?? [] : []
	);

	const recentEpisodesAvailable = computed(() => isTitleShow(titleDetail.value) && recentEpisodes.value.length > 0);

	const credits = computed(() => {
		if (isTitleSeason(titleDetail.value)) {
			return titleDetail.value?.show.content.credits ?? [];
		}
		if (titleDetail.value?.__typename === 'Movie' || titleDetail.value?.__typename === 'Show') {
			return titleDetail.value?.content.credits ?? [];
		}
		// should never happen™
		return [];
	});

	const titleFullPath = computed(() =>
		isTitleSeason(titleDetail.value)
			? titleDetail.value?.show.content.fullPath
			: titleDetail.value?.content.fullPath
	);

	const isTitleUpcoming = computed(() => !titleDetail.value?.content.isReleased);

	const showDetails = computed(() => {
		if (isTitleSeason(titleDetail.value)) {
			const show = titleDetail.value?.show;
			return {
				id: show.id,
				objectType: show.objectType,
				objectId: show.objectId,
				title: show.content?.title,
				isReleased: show.content?.isReleased,
			};
		}
		return null;
	});

	const originalTitle = computed(() =>
		!isTitleSeason(titleDetail.value) && titleDetail.value ? titleDetail.value?.content.originalTitle : ''
	);

	return {
		title,
		titleName,
		titleId,
		titleObjectId,
		titleObjectType,
		titlePosterUrl,
		titleBackdrops,
		seasonName,
		seasonNumber,
		seasonEpisodes,
		seasonTotalEpisodeCount,
		isShow: isShowComputed,
		isSeason: isSeasonComputed,
		showSeasonsAvailable,
		recentEpisodes,
		recentEpisodesAvailable,
		credits,
		titleFullPath,
		isTitleUpcoming,
		showDetails,
		originalTitle,
	};
}

import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type EpisodeFragment = {
	__typename?: 'Episode';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	uniqueOfferCount: number;
	seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	flatrate: Array<{
		__typename?: 'Offer';
		id: string;
		package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
	}>;
	buy: Array<{
		__typename?: 'Offer';
		id: string;
		package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
	}>;
	rent: Array<{
		__typename?: 'Offer';
		id: string;
		package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
	}>;
	free: Array<{
		__typename?: 'Offer';
		id: string;
		package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
	}>;
	fast: Array<{
		__typename?: 'Offer';
		id: string;
		package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
	}>;
	content: {
		__typename?: 'EpisodeContent';
		title: string;
		shortDescription?: string | null;
		episodeNumber: number;
		seasonNumber: number;
		isReleased: boolean;
		runtime?: number | null;
		upcomingReleases: Array<{
			__typename?: 'UpcomingRelease';
			releaseDate?: any | null;
			label: Types.UpcomingReleaseLabel;
			package?: { __typename?: 'Package'; id: string; packageId: number } | null;
		}>;
	};
};

export const EpisodeFragmentDoc = gql`
	fragment Episode on Episode {
		id
		objectId
		objectType
		seenlistEntry {
			createdAt
		}
		uniqueOfferCount: offerCount(country: $country, platform: $platform, filter: { bestOnly: true })
		flatrate: offers(
			country: $country
			platform: $platform
			filter: { monetizationTypes: [FLATRATE_AND_BUY, FLATRATE, ADS, CINEMA, FREE], bestOnly: true }
		) {
			id
			package {
				id
				clearName
				packageId
			}
		}
		buy: offers(country: $country, platform: $platform, filter: { monetizationTypes: [BUY], bestOnly: true }) {
			id
			package {
				id
				clearName
				packageId
			}
		}
		rent: offers(country: $country, platform: $platform, filter: { monetizationTypes: [RENT], bestOnly: true }) {
			id
			package {
				id
				clearName
				packageId
			}
		}
		free: offers(
			country: $country
			platform: $platform
			filter: { monetizationTypes: [ADS, FREE], bestOnly: true }
		) {
			id
			package {
				id
				clearName
				packageId
			}
		}
		fast: offers(country: $country, platform: $platform, filter: { monetizationTypes: [FAST], bestOnly: true }) {
			id
			package {
				id
				clearName
				packageId
			}
		}
		content(country: $country, language: $language) {
			title
			shortDescription
			episodeNumber
			seasonNumber
			isReleased
			runtime
			upcomingReleases {
				releaseDate
				label
				package {
					id
					packageId
				}
			}
		}
	}
`;


import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { getCtr } from '@/helpers/modal-helper';
import type { Content } from '@/interfaces/platform';
import type Provider from '@/interfaces/provider';

const Constant = namespace('constant');

@Component({
	name: 'NewDesignModal',
	components: {},
})
export default class NewDesignModal extends Vue {
	mCtr: any;

	@Prop({ default: '' }) title: string;
	@Prop({ default: true }) closable: boolean;
	@Prop({ default: false }) hasBack: boolean;
	@Prop({ default: true }) hasHeader: boolean;
	@Prop({ default: true }) showDefaultTitle: boolean;
	@Prop({ default: true }) hasLargeToolbar: boolean;

	@Ref('content') content: Content;

	@Constant.Getter providers: () => Provider[];

	async mounted() {
		this.mCtr = await getCtr();

		const modal = (await this.mCtr.getTop()) as HTMLIonModalElement;
		await modal?.onWillDismiss();
		this.$emit('closed');
	}

	get ASSETS_DIR(): string {
		return `/${ASSETS_DIR}`;
	}

	get descriptionProviderList() {
		return this.providers()
			.slice(0, 3)
			.map((provider, index) => ` ${provider.clearName}`);
	}

	async close() {
		this.mCtr.dismiss();
		this.content?.scrollToTop();
	}
}

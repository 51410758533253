import { render, staticRenderFns } from "./TitleDetailPosterInfoV1.vue?vue&type=template&id=681f1df5&scoped=true"
import script from "./TitleDetailPosterInfoV1.vue?vue&type=script&setup=true&lang=ts"
export * from "./TitleDetailPosterInfoV1.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TitleDetailPosterInfoV1.vue?vue&type=style&index=0&id=681f1df5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681f1df5",
  null
  
)

export default component.exports
import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetTitleDetailArticleQueryVariables = Types.Exact<{
	fullPath: Types.Scalars['String']['input'];
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
}>;

export type GetTitleDetailArticleQuery = {
	__typename?: 'Query';
	urlV2: {
		__typename?: 'Url';
		id: string;
		node:
			| { __typename?: 'Article'; id: string }
			| { __typename?: 'Author'; id: string }
			| { __typename?: 'Bundle'; id: string }
			| { __typename?: 'Device'; id: string }
			| { __typename?: 'Episode'; id: string }
			| { __typename?: 'GenericTitleList'; id: string }
			| { __typename?: 'Genre'; id: string }
			| {
					__typename?: 'Movie';
					id: string;
					content: {
						__typename?: 'MovieContent';
						articles: Array<{
							__typename?: 'Article';
							id: string;
							content: {
								__typename: 'ArticleContent';
								synopsis?: string | null;
								whatToKnow?: string | null;
								productionNews?: string | null;
							};
						}>;
					};
			  }
			| { __typename?: 'MultiStepSportEvent'; id: string }
			| { __typename?: 'Offer'; id: string }
			| { __typename?: 'Package'; id: string }
			| { __typename?: 'Page'; id: string }
			| { __typename?: 'Person'; id: string }
			| {
					__typename?: 'Season';
					id: string;
					content: {
						__typename?: 'SeasonContent';
						articles: Array<{
							__typename?: 'Article';
							id: string;
							content: {
								__typename: 'ArticleContent';
								synopsis?: string | null;
								whatToKnow?: string | null;
								productionNews?: string | null;
							};
						}>;
					};
			  }
			| {
					__typename?: 'Show';
					id: string;
					content: {
						__typename?: 'ShowContent';
						articles: Array<{
							__typename?: 'Article';
							id: string;
							content: {
								__typename: 'ArticleContent';
								synopsis?: string | null;
								whatToKnow?: string | null;
								productionNews?: string | null;
							};
						}>;
					};
			  }
			| { __typename?: 'SingleStepSportEvent'; id: string }
			| { __typename?: 'SportCompetitionV2'; id: string }
			| { __typename?: 'SportCompetitorV2'; id: string }
			| { __typename?: 'Url'; id: string };
	};
};

export type TitleDetailArticleFragment = {
	__typename?: 'Article';
	id: string;
	content: {
		__typename: 'ArticleContent';
		synopsis?: string | null;
		whatToKnow?: string | null;
		productionNews?: string | null;
	};
};

export const TitleDetailArticleFragmentDoc = gql`
	fragment TitleDetailArticle on Article {
		id
		content {
			__typename
			synopsis
			whatToKnow
			productionNews
		}
	}
`;
export const GetTitleDetailArticleDocument = gql`
	query GetTitleDetailArticle($fullPath: String!, $country: Country!, $language: Language!) {
		urlV2(fullPath: $fullPath) {
			id
			node {
				id
				... on MovieOrShowOrSeason {
					content(country: $country, language: $language) {
						articles(articleTypes: TITLE_DETAIL_ARTICLE) {
							...TitleDetailArticle
						}
					}
				}
			}
		}
	}
	${TitleDetailArticleFragmentDoc}
`;

import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { TitleOfferFragmentDoc, FastOfferFragmentDoc } from '../../buybox/graphql/fragments/Offer.fragment';
export type GetSimilarTitlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	titleId: Types.Scalars['ID']['input'];
	language: Types.Scalars['Language']['input'];
	filters?: Types.InputMaybe<Types.TitleFilter>;
	includeOffers?: Types.Scalars['Boolean']['input'];
	first?: Types.Scalars['Int']['input'];
	offerFilters?: Types.OfferFilter;
}>;

export type GetSimilarTitlesQuery = {
	__typename?: 'Query';
	node?:
		| { __typename?: 'Article'; id: string }
		| { __typename?: 'Author'; id: string }
		| { __typename?: 'Bundle'; id: string }
		| { __typename?: 'Device'; id: string }
		| { __typename?: 'Episode'; id: string }
		| { __typename?: 'GenericTitleList'; id: string }
		| { __typename?: 'Genre'; id: string }
		| {
				__typename?: 'Movie';
				id: string;
				similarTitlesV2: {
					__typename?: 'SimilarTitlesConnection';
					edges?: Array<{
						__typename?: 'SimilarTitlesEdge';
						node:
							| {
									__typename?: 'Movie';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									offers?: Array<{
										__typename?: 'Offer';
										id: string;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										currency?: string | null;
										lastChangeRetailPriceValue?: number | null;
										type: Types.OfferType;
										standardWebURL?: string | null;
										streamUrl?: string | null;
										elementCount?: number | null;
										availableTo?: any | null;
										subtitleLanguages: Array<any>;
										videoTechnology: Array<Types.VideoTechnology>;
										audioTechnology: Array<Types.AudioTechnology>;
										audioLanguages: Array<any>;
										deeplinkRoku?: string | null;
										package: {
											__typename?: 'Package';
											id: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											icon: string;
											iconWide: string;
											hasRectangularIcon: boolean;
											planOffers: Array<{
												__typename?: 'PackagePlanOffer';
												title?: string | null;
												retailPrice?: string | null;
												isTrial?: boolean | null;
												durationDays?: number | null;
												retailPriceValue?: number | null;
												children: Array<{
													__typename?: 'PackagePlanOffer';
													title?: string | null;
													retailPrice?: string | null;
													isTrial?: boolean | null;
													durationDays?: number | null;
													retailPriceValue?: number | null;
												}>;
											}>;
										};
									}>;
									seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									content: {
										__typename?: 'MovieContent';
										title: string;
										posterUrl?: string | null;
										fullPath: string;
										genres: Array<{ __typename?: 'Genre'; translation: string }>;
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbVotes?: number | null;
											imdbScore?: number | null;
											tomatoMeter?: number | null;
											certifiedFresh?: boolean | null;
											jwRating?: number | null;
										};
										interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
									};
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							  }
							| {
									__typename?: 'Show';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									offers?: Array<{
										__typename?: 'Offer';
										id: string;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										currency?: string | null;
										lastChangeRetailPriceValue?: number | null;
										type: Types.OfferType;
										standardWebURL?: string | null;
										streamUrl?: string | null;
										elementCount?: number | null;
										availableTo?: any | null;
										subtitleLanguages: Array<any>;
										videoTechnology: Array<Types.VideoTechnology>;
										audioTechnology: Array<Types.AudioTechnology>;
										audioLanguages: Array<any>;
										deeplinkRoku?: string | null;
										package: {
											__typename?: 'Package';
											id: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											icon: string;
											iconWide: string;
											hasRectangularIcon: boolean;
											planOffers: Array<{
												__typename?: 'PackagePlanOffer';
												title?: string | null;
												retailPrice?: string | null;
												isTrial?: boolean | null;
												durationDays?: number | null;
												retailPriceValue?: number | null;
												children: Array<{
													__typename?: 'PackagePlanOffer';
													title?: string | null;
													retailPrice?: string | null;
													isTrial?: boolean | null;
													durationDays?: number | null;
													retailPriceValue?: number | null;
												}>;
											}>;
										};
									}>;
									seenState: {
										__typename?: 'ShowSeenState';
										progress: number;
										seenEpisodeCount: number;
									};
									content: {
										__typename?: 'ShowContent';
										title: string;
										posterUrl?: string | null;
										fullPath: string;
										genres: Array<{ __typename?: 'Genre'; translation: string }>;
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbVotes?: number | null;
											imdbScore?: number | null;
											tomatoMeter?: number | null;
											certifiedFresh?: boolean | null;
											jwRating?: number | null;
										};
										interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
									};
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							  };
					}> | null;
				};
		  }
		| { __typename?: 'MultiStepSportEvent'; id: string }
		| { __typename?: 'Offer'; id: string }
		| { __typename?: 'Package'; id: string }
		| { __typename?: 'Page'; id: string }
		| { __typename?: 'Person'; id: string }
		| { __typename?: 'Season'; id: string }
		| {
				__typename?: 'Show';
				id: string;
				similarTitlesV2: {
					__typename?: 'SimilarTitlesConnection';
					edges?: Array<{
						__typename?: 'SimilarTitlesEdge';
						node:
							| {
									__typename?: 'Movie';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									offers?: Array<{
										__typename?: 'Offer';
										id: string;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										currency?: string | null;
										lastChangeRetailPriceValue?: number | null;
										type: Types.OfferType;
										standardWebURL?: string | null;
										streamUrl?: string | null;
										elementCount?: number | null;
										availableTo?: any | null;
										subtitleLanguages: Array<any>;
										videoTechnology: Array<Types.VideoTechnology>;
										audioTechnology: Array<Types.AudioTechnology>;
										audioLanguages: Array<any>;
										deeplinkRoku?: string | null;
										package: {
											__typename?: 'Package';
											id: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											icon: string;
											iconWide: string;
											hasRectangularIcon: boolean;
											planOffers: Array<{
												__typename?: 'PackagePlanOffer';
												title?: string | null;
												retailPrice?: string | null;
												isTrial?: boolean | null;
												durationDays?: number | null;
												retailPriceValue?: number | null;
												children: Array<{
													__typename?: 'PackagePlanOffer';
													title?: string | null;
													retailPrice?: string | null;
													isTrial?: boolean | null;
													durationDays?: number | null;
													retailPriceValue?: number | null;
												}>;
											}>;
										};
									}>;
									seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									content: {
										__typename?: 'MovieContent';
										title: string;
										posterUrl?: string | null;
										fullPath: string;
										genres: Array<{ __typename?: 'Genre'; translation: string }>;
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbVotes?: number | null;
											imdbScore?: number | null;
											tomatoMeter?: number | null;
											certifiedFresh?: boolean | null;
											jwRating?: number | null;
										};
										interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
									};
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							  }
							| {
									__typename?: 'Show';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									offers?: Array<{
										__typename?: 'Offer';
										id: string;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										currency?: string | null;
										lastChangeRetailPriceValue?: number | null;
										type: Types.OfferType;
										standardWebURL?: string | null;
										streamUrl?: string | null;
										elementCount?: number | null;
										availableTo?: any | null;
										subtitleLanguages: Array<any>;
										videoTechnology: Array<Types.VideoTechnology>;
										audioTechnology: Array<Types.AudioTechnology>;
										audioLanguages: Array<any>;
										deeplinkRoku?: string | null;
										package: {
											__typename?: 'Package';
											id: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											icon: string;
											iconWide: string;
											hasRectangularIcon: boolean;
											planOffers: Array<{
												__typename?: 'PackagePlanOffer';
												title?: string | null;
												retailPrice?: string | null;
												isTrial?: boolean | null;
												durationDays?: number | null;
												retailPriceValue?: number | null;
												children: Array<{
													__typename?: 'PackagePlanOffer';
													title?: string | null;
													retailPrice?: string | null;
													isTrial?: boolean | null;
													durationDays?: number | null;
													retailPriceValue?: number | null;
												}>;
											}>;
										};
									}>;
									seenState: {
										__typename?: 'ShowSeenState';
										progress: number;
										seenEpisodeCount: number;
									};
									content: {
										__typename?: 'ShowContent';
										title: string;
										posterUrl?: string | null;
										fullPath: string;
										genres: Array<{ __typename?: 'Genre'; translation: string }>;
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbVotes?: number | null;
											imdbScore?: number | null;
											tomatoMeter?: number | null;
											certifiedFresh?: boolean | null;
											jwRating?: number | null;
										};
										interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
									};
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							  };
					}> | null;
				};
		  }
		| { __typename?: 'SingleStepSportEvent'; id: string }
		| { __typename?: 'SportCompetitionV2'; id: string }
		| { __typename?: 'SportCompetitorV2'; id: string }
		| { __typename?: 'Url'; id: string }
		| null;
};

export type GetSimilarUpcomingTitlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	filters?: Types.InputMaybe<Types.TitleFilter>;
}>;

export type GetSimilarUpcomingTitlesQuery = {
	__typename?: 'Query';
	newTitles: {
		__typename?: 'NewTitlesConnection';
		edges?: Array<{
			__typename?: 'NewTitlesEdge';
			node:
				| {
						__typename?: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						content: {
							__typename?: 'MovieContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							genres: Array<{ __typename?: 'Genre'; translation: string }>;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbVotes?: number | null;
								imdbScore?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
								jwRating?: number | null;
							};
							interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
						};
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				  }
				| {
						__typename?: 'Season';
						objectId: number;
						objectType: Types.ObjectType;
						id: string;
						content: {
							__typename?: 'SeasonContent';
							title: string;
							posterUrl?: string | null;
							fullPath: string;
							seasonNumber: number;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							scoring: { __typename?: 'Scoring'; imdbScore?: number | null };
						};
						show: {
							__typename?: 'Show';
							id: string;
							objectId: number;
							objectType: Types.ObjectType;
							content: { __typename?: 'ShowContent'; title: string };
						};
				  };
		}> | null;
	};
};

export type SimilarTitle_Movie_Fragment = {
	__typename?: 'Movie';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	content: {
		__typename?: 'MovieContent';
		title: string;
		posterUrl?: string | null;
		fullPath: string;
		genres: Array<{ __typename?: 'Genre'; translation: string }>;
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbVotes?: number | null;
			imdbScore?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
			jwRating?: number | null;
		};
		interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
	};
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type SimilarTitle_Show_Fragment = {
	__typename?: 'Show';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	seenState: { __typename?: 'ShowSeenState'; progress: number; seenEpisodeCount: number };
	content: {
		__typename?: 'ShowContent';
		title: string;
		posterUrl?: string | null;
		fullPath: string;
		genres: Array<{ __typename?: 'Genre'; translation: string }>;
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbVotes?: number | null;
			imdbScore?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
			jwRating?: number | null;
		};
		interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
	};
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type SimilarTitleFragment = SimilarTitle_Movie_Fragment | SimilarTitle_Show_Fragment;

export const SimilarTitleFragmentDoc = gql`
	fragment SimilarTitle on MovieOrShow {
		id
		objectId
		objectType
		content(country: $country, language: $language) {
			title
			posterUrl
			fullPath
			genres {
				translation(language: $language)
			}
			backdrops {
				backdropUrl
			}
			scoring {
				imdbVotes
				imdbScore
				tomatoMeter
				certifiedFresh
				jwRating
			}
			interactions {
				votesNumber
			}
		}
		watchlistEntryV2 {
			createdAt
		}
		likelistEntry {
			createdAt
		}
		dislikelistEntry {
			createdAt
		}
		... on Movie {
			seenlistEntry {
				createdAt
			}
		}
		... on Show {
			seenState(country: $country) {
				progress
				seenEpisodeCount
			}
		}
	}
`;
export const GetSimilarTitlesDocument = gql`
	query GetSimilarTitles(
		$country: Country!
		$titleId: ID!
		$language: Language!
		$filters: TitleFilter
		$includeOffers: Boolean! = false
		$first: Int! = 12
		$offerFilters: OfferFilter! = { packages: ["atp"] }
	) {
		node(id: $titleId) {
			id
			... on MovieOrShow {
				similarTitlesV2(country: $country, filter: $filters, first: $first) {
					edges {
						node {
							...SimilarTitle
							offers(country: $country, platform: WEB, filter: $offerFilters)
								@include(if: $includeOffers) {
								...TitleOffer
							}
						}
					}
				}
			}
		}
	}
	${SimilarTitleFragmentDoc}
	${TitleOfferFragmentDoc}
`;
export const GetSimilarUpcomingTitlesDocument = gql`
	query GetSimilarUpcomingTitles($country: Country!, $language: Language!, $filters: TitleFilter) {
		newTitles(country: $country, pageType: UPCOMING, filter: $filters) {
			edges {
				node {
					id
					... on MovieOrShow {
						...SimilarTitle
					}
					... on Season {
						objectId
						objectType
						content(country: $country, language: $language) {
							title
							posterUrl
							fullPath
							backdrops {
								backdropUrl
							}
							scoring {
								imdbScore
							}
							seasonNumber
						}
						show {
							id
							objectId
							objectType
							content(country: $country, language: $language) {
								title
							}
						}
					}
				}
			}
		}
	}
	${SimilarTitleFragmentDoc}
`;

import { ClipProvider } from '@/@types/graphql-types';
import { TrailerClip } from '@/interfaces/trailerClip';

export function getTrailerComponent(provider: TrailerClip['provider']) {
	switch (provider) {
		case ClipProvider.Videobuster:
			return () => import(/* webpackChunkName: "videobuster-player" */ '@/ui-components/VideobusterPlayer.vue');
		case ClipProvider.Dailymotion:
			return () => import(/* webpackChunkName: "dailymotion-player" */ '@/ui-components/DailyMotionPlayer.vue');
		case 'JUSTWATCH_TV':
			return () => import(/* webpackChunkName: "dailymotion-player" */ '@/ui-components/DailyMotionPlayer.vue');
		case 'PLEX':
			return () => import(/* webpackChunkName: "plex-player" */ '@/ui-components/PlexPlayer.vue');

		default:
			return () => import(/* webpackChunkName: "youtube-player" */ '@/ui-components/YoutubePlayerGraphql.vue');
	}
}

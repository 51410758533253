import { defineComponent, inject } from 'vue';

import type {
	VisibilityChangedPayload,
	BatchImpressionTrackingOptions,
} from '@/helpers/composables/useBatchImpressionTracking';
import { useBatchImpressionTracking } from '@/helpers/composables/useBatchImpressionTracking';

import { SraEventAction, type ObjectType } from '@/@types/graphql-types';
import type { TitleContentType } from '@/interfaces/snowplow/title-context';

import { SnowplowTitleContextGraphql } from '@/helpers/tracking/providers/snowplow-contexts';
import { getSrPlacement, SR_PROVIDER_KEY, SRCatalogueKey } from '@/components/sponsored-recommendations/useSendBidId';
import { useBackendTracking } from './useBackendTracking';

export type PosterVisibilityChangedPayload = VisibilityChangedPayload<any>;
export interface ImpressionTrackingPosterDetails {
	titleId: number;
	entityId?: string;
	objectType: ObjectType;
	seasonNumber?: number;
	episodeNumber?: number;
	contentType?: TitleContentType;
	onVisibilityCallback?: void;
}

/**
 * Impression Tracking handles
 * - Sponsored Recommendation's holdout
 * - Fires when the poster is visible in the user's viewport
 * - Snowplow Title Context Graphql
 *
 * @param option: BatchImpressionTrackingOptions
 * @returns ImpressionTrackingPosterDetails
 */
export function usePosterImpressionTracking(options: BatchImpressionTrackingOptions) {
	const { trackSraEvent } = useBackendTracking();
	const srCategoryKey = inject<SRCatalogueKey | undefined>(SR_PROVIDER_KEY, undefined);

	// Called when component is visible on the screen
	function onVisibilityCallback({ entityId }: ImpressionTrackingPosterDetails) {
		if (srCategoryKey == null) return;
		const { sponsoredAd } = getSrPlacement(srCategoryKey);

		if (sponsoredAd?.campaign == null || sponsoredAd?.bidId == null) return;

		// Check to see if the holdout campaign matches the poster's entityId
		if (sponsoredAd?.holdoutGroup && sponsoredAd?.campaign?.node?.nodeId === entityId) {
			trackSraEvent({
				action: SraEventAction.Impression,
				sponsoredAd: sponsoredAd,
			});
		}
	}

	return useBatchImpressionTracking<ImpressionTrackingPosterDetails>({
		category: 'title',
		toContext: getPosterImpressionContext,
		observableOptions: options.observableOptions,
		additionalContexts: options.additionalContexts,
		onVisibilityCallback,
	});
}

/** Renderless version */
export default defineComponent({
	name: 'UsePosterImpressionTracking',
	props: ['observableOptions', 'additionalContexts'],
	setup(props: BatchImpressionTrackingOptions, { slots }) {
		return () => slots.default?.(usePosterImpressionTracking(props)) ?? slots[0];
	},
});

function getPosterImpressionContext({
	titleId,
	objectType,
	seasonNumber,
	episodeNumber,
	contentType,
}: ImpressionTrackingPosterDetails): SnowplowTitleContextGraphql {
	return new SnowplowTitleContextGraphql(titleId, objectType, seasonNumber, episodeNumber, contentType);
}
